.cartPage {
  background-color: lightgray;
  /* position: fixed; */
  /* width: 100%; */

  z-index: 1;
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}

.cartPage.open {
  transform: translateY(0);
}

.cart {
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  /* background-color: rgb(231, 231, 231); */
  background-color: white;
  border: 1px solid lightgray;
}

.btn-cart {
  position: fixed;
  top: 100%;
  left: 100%;
  transform: translate(-150%, -100%);
  background-color: orange;
  z-index: 1;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.2);
}

@media (max-width: 715px) {
  .opened {
    display: none;
  }
}

.cart-list-body {
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(240, 240, 240);
  padding: 5px;
  margin: 5px;
  border-radius: 3px;
  flex-direction: row;
  justify-content: space-between;
  /* flex-direction: row; */
}

.cart-list-left {
  flex: 10 1 200px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  /* justify-content: center; */
}

.cart-amount {
  display: flex;
  flex-direction: row;
  background-color: rgb(190, 223, 236);
  /* padding: 0 5px; */
  border-radius: 5px;
  /* cursor: pointer; */
}

.cart-amount-btn {
  cursor: pointer;
  padding: 5px;
}

.cart-amount-input {
  border: 0px;
  background-color: rgb(213, 230, 236);
}

.cart-list-right {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.cart-delbtn {
  background-color: rgb(170, 0, 0);
  color: white;
  cursor: pointer;
  border-radius: 3px;
}

.mmodal {
  /* margin: 10px; */
  /* margin-bottom: 70px; */
  height: 100%;
}

.mcol-md {
  padding: 5px;
}

.cart-list-left-row1 {
  flex: 1 1 200px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  /* justify-content: space-between; */
  background-color: rgb(221, 242, 255);
  color: rgb(0, 7, 48);
  border-radius: 5px;
  justify-content: center;
}

.cart-list-left-row2 {
  flex: 1 1 200px;
  flex-grow: 3;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  /* justify-content: center; */
}

.cust {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: rgb(226, 226, 226);
  border-radius: 5px;
  padding: 5px;
  margin: 2px;
}

.cust-body {
  /* flex-grow: 1; */
  padding: 5px;
  align-items: center;
  flex: 1 0 0;
}

.cust-body-btn {
  /* flex-grow: 1; */

  align-items: center;
  /* flex: 1 1 200px; */
  flex: 1 0 0;
}

.mmodal-footer {
  display: flex;
  padding: 5px;
  justify-content: center;
  /* background-color: rgb(255, 241, 180); */
  /* width: 100%; */
  /* position: fixed; */
  /* top: 100%;
  left: 100%;
  width: 100%;
  transform: translate(-100%, -100%); */
}

.footer-btn {
  /* flex-grow: 1; */
}

.submit-zone {
  display: flex;
  flex-direction: column;
}

.cust-search {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.cust-search-1 {
  flex: 0 0 40%;
  position: relative;
  vertical-align: middle;
}

.cust-search-2 {
  flex: 0 0 60%;
  /* position: relative;
  width: 100%; */
}

.btn-s {
  flex-grow: 1;
  white-space: pre;
  /* background-color: green; */
}

.mbtn-group {
  position: relative;
}

/* .mrow {
  display: flex;
  flex-direction: row;
  width: 100%;
} */

.mcol-2 {
  flex: 0 0 30%;
}

.mcol-3 {
  flex: 0 0 70%;
}

.btn-cart-add {
  color: white;
  background-color: rgb(92, 167, 211);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px gray;
  margin: 5px;
  cursor: pointer;
}
