.isHide {
  display: none;
}

.mdisabled {
  opacity: 0.5;
}

.custdetail {
  background-color: rgb(226, 226, 226);
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 5px;
  border: 1px solid rgb(173, 173, 173);
}

.mcard-bodyclm {
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
}

.cardContainer {
  background-color: rgb(197, 197, 197);
  border-radius: 10px;
  margin: 5px;
  padding: 10px 5px;
}

.mmodal {
  /* position: fixed; */
  z-index: 2;
  margin: auto;
  /* width: 80%; */
  /* display: flex; */
}

.mcard {
  /* border: 1px; */
  /* border-color: black; */
  /* flex-direction: row; */
  padding: 4px 10px;
}

.mcard-body {
  border-radius: 5px;
  display: flex;
  /* flex-wrap: wrap; */
  background-color: rgb(240, 240, 240);
  flex-direction: row;
}

.mcard-bodyclm {
  border-radius: 5px;
  display: flex;
  background-color: rgb(240, 240, 240);
  flex-direction: column;
}

.mcard-bodyclm-clm {
  display: flex;

  flex-grow: 1;
  padding: 5px;
  flex: 1 1 0px;
  flex-direction: column;
}

.mcard-bodyclm-row {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: 5px;
  /* flex: 1 1 20px; */
  flex-direction: row;
}

.mcard-body-left {
  display: flex;
  justify-content: center;
  /* flex: 1 1 135px; */
  flex: 3 0 130px;
  flex-direction: column;
  /* padding: 5px; */
  margin: 0px;
}

.mcard-body-price {
  display: flex;
  /* justify-content: center; */
  /* flex-grow: 1; */
  /* padding: 5px; */
  flex: 1 0 40px;
  flex-direction: column;
}

.mcard-body-else {
  display: flex;
  justify-content: center;
  /* flex-grow: 1; */
  padding: 5px;
  flex: 1 1 20px;
  flex-direction: row;
}

.btn-added {
  background-color: lightsalmon;
  cursor: not-allowed !important;
  /* color: transparent; */
}

.btn-norm {
  background-color: salmon;
  color: white;
  font-size: 1rem;
  cursor: cell !important;
}

.hide {
  display: none;
}
