body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.role {
  background: transparent;
  border-radius: 3px;
}
.role-admin {
  color: #d4af37;
}

.role-user {
  color: lightgray;
}

.bd {
  background-color: grey;
  color: white;
  border: 1px solid #b3b3b3;
}

.tb {
  background-color: rgb(197, 197, 197);
  padding: 5px;
  border-radius: 5px;
}

.table-head {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.table-body {
  background-color: rgb(220, 220, 220);
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
}

.table-sub {
  margin-top: 5px;
  padding: 5px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.aright {
  margin: auto;
  text-align: right;
}

.acenter {
  margin: auto;
  text-align: center;
}

.mgh5 {
  margin: auto 5px !important;
}

.mg5 {
  margin: 5px;
}

.textcenter {
  text-align: center;
}

.center {
  margin: auto;
  text-align: center;
}

.cc {
  justify-content: center;
  align-items: center;
}

.nw {
  white-space: nowrap;
}

.mg {
  margin: auto;
}

.mg0 {
  margin: 0px;
}

.flexrow {
  display: flex;
  flex-direction: row;
}

.flexcol {
  display: flex;
  flex-direction: column;
}

.foption {
  flex: 1 0 0;
  padding: 3px;
}

.flex0 {
  flex: 0 0 0;
}
.flex1 {
  flex: 1 0 0 !important;
}
.flex2 {
  flex: 2 0 0;
}
.flex3 {
  flex: 3 0 0;
}
.flex4 {
  flex: 4 0 0;
}
.flex5 {
  flex: 5 0 0;
}
.flex10 {
  flex: 10 0 0;
}

.flex05 {
  flex: 0.5 0 0;
}

.flex-spa {
  display: flex;
  justify-content: space-around;
}

.flex-spb {
  display: flex;
  justify-content: space-between;
}

.range {
  color: #999999;
}

.amount-bg {
  background-color: rgb(224, 224, 224);
  padding: 0px 5px;
  margin-right: 5px;
  margin-top: 5px;
  border-radius: 5px;
}

.adj-btn {
  margin: 5px;
  background-color: lightgrey;
  color: black;
}

.cblue {
  color: #1a75ff;
}

.cgreen {
  color: green;
}

.grade {
  background-color: lightgrey;
  padding: 0px 5px;
  margin: 0px 5px;
}

.max {
  background-color: #d6e4ff;
}

.min {
  background-color: #ffe1c4;
}

.cartFooter {
  display: flex;
  background-color: white;
  padding: 10px;
  /* margin: 0; */
  flex: 0 1 500px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  border-radius: 20px;
  border: 1px solid #ced4da;
}

.prodBlock {
  background-color: #808080;
  border-radius: 5px;
  margin: 1px;
}

.border {
  border: 1px solid black;
  border-radius: 5px;
}

.btn-menu {
  cursor: pointer;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  background-color: lightgray;
  flex: 1 0;
  text-align: center;
  margin: 3px;
  border-radius: 5px;
  border: 1px solid black;
}

.btn-mini {
  cursor: pointer;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  background-color: lightgray;
  flex: 1 0;
  text-align: center;
  margin: 3px;
  border-radius: 5px;
  border: 1px solid black;
}

.btn-shadow {
  box-shadow: 0px 2px 3px gray;
  cursor: pointer;
}

.font-size-120 {
  font-size: 1.2rem;
}
