.mdisabled {
  opacity: 0.35;
}

.c1 {
  background-color: lightblue;
}

.c2 {
  background-color: lightgreen;
}

.c3 {
  background-color: #ccccff;
}

.c4 {
  background-color: lightsalmon;
}

.c5 {
  background-color: lightseagreen;
}

.c6 {
  background-color: lightpink;
}

.c7 {
  background-color: lightyellow;
}

.c8 {
  background-color: #ffe066;
}

.cn {
  background-color: #a6a6a6;
}

.bg-lg {
  background-color: lightgray;
}
